import React from "react";
import log from "loglevel";
import { useLocalObservable } from "mobx-react-lite";
import {
  flow,
  getEnv,
  getParentOfType,
  Instance,
  onSnapshot,
  SnapshotIn,
  types,
} from "mobx-state-tree";
import {
  BearerApi,
  NoCloudFunctionData,
  NoOnboardingData,
  RemoteConfigBaseUrl,
  OnboardingRemoteConfigParameters,
  TBearerApi,
  TLoginResponse,
  TOnboardingAddMotorbikeRequest,
  TOnboardingAddMotorbikeResponse,
  TOnboardingConfirmSMSRequest,
  TOnboardingConfirmSMSResponse,
  TOnboardingDigitalSignatureRequest,
  TOnboardingDigitalSignatureResponse,
  TOnboardingEditMotorbikeRequest,
  TOnboardingEditMotorbikeResponse,
  TOnboardingGetNccLinkResponse,
  TOnboardingGetStripeExpressLinkResponse,
  TOnboardingLegalDetailsRequest,
  TOnboardingLegalDetailsResponse,
  TOnboardingLoginPermissionResponse,
  TOnboardingModesOfTransportRequest,
  TOnboardingModesOfTransportResponse,
  TOnboardingNccNextButtonResponse,
  TOnboardingNotifySetPasswordRequest,
  TOnboardingNotifySetPasswordResponse,
  TOnboardingPersonalDetailsRequest,
  TOnboardingPersonalDetailsResponse,
  TOnboardingRemoveMotorbikeResponse,
  TOnboardingSendSMSRequest,
  TOnboardingSendSMSResponse,
  TOnboardingStripeResponse,
  TSignUpResponse,
} from "../api/bearer-api";
import { error } from "../components/error/error";

export enum AnswersOfIdVerificationEnum {
  Successful = "successful",
  CheckIdVerification = "CheckIdVerification",
  InitiateIdVerification = "InitiateIdVerification",
  CallSupportForIDV = "CallSupportForIDV",
}

export enum StripeResponseEnum {
  Successful = "successful",
  UnSuccessful = "UnSuccessful",
  StripeButton = "StripeButton",
  MainStripeButton = "MainStripeButton",
}
export enum StepsEnum {
  TermsAndConditionsAndPrivacyPolicy = "Terms & Conditions",
  ContactDetails = "Contact Details",
  PersonalDetails = "Personal Details",
  LegalDetails = "Legal Details",
  ModesOfTransport = "Modes of Transport",
  StripeOnboarding = "Payment Setup",
  IDVerification = "ID Checks",
  DigitalSignature = "Digital Signature",
}

export const StepsEnumValues = [...Object.values(StepsEnum)];

export enum AuthenticationStepsEnum {
  Email = "Email",
  Password = "Password",
  MobileNumber = "Mobile Number",
}
export const AuthenticationStepsEnumValues = [
  ...Object.values(AuthenticationStepsEnum),
];

export enum GenderEnum {
  Male = "Male",
  Female = "Female",
  NoneBinary = "None-binary",
  PreferNotToSay = "Prefer not to say",
}
export const GenderEnumValues = [...Object.values(GenderEnum)];

export enum StatesEnum {
  AustraliaCapital = "ACT",
  NewSouthWales = "NSW",
  NorthernTerritory = "NT",
  Queensland = "QLD",
  SouthAustralia = "SA",
  Tasmania = "TAS",
  Victoria = "VIC",
  WesternAustralia = "WA",
}
export const StatesEnumValues = [...Object.values(StatesEnum)];

export enum ReferredByEnum {
  SocialMediaAds = "Social Media Ads",
  GumtreeJobAds = "Gumtree (Job Ads)",
  JoraJobAds = "Jora (Job Ads)",
  SEEKJobAds = "SEEK (Job Ads)",
  IndeedJobAds = "Indeed (Job Ads)",
  FacebookGroups = "Facebook Groups",
  EmailNewsletters = "Email & Newsletters",
  SearchEngine = "Search Engine",
  ReferralCode = "Referral Code",
  FlyersPublicCampaigns = "Flyers (Public Campaigns)",
  Other = "Other",
}
export const ReferredByEnumValues = [...Object.values(ReferredByEnum)];

export enum CycleEnum {
  HasCycle = "HasCycle",
  HasNotCycle = "HasNotCycle",
}
export enum MotorbikeEnum {
  HasMotorbike = "HasMotorbike",
  HasNotMotorbike = "HasNotMotorbike",
}
export enum OwnEnum {
  Yes = "Yes",
  No = "No",
}
export enum MotorbikeTypeEnum {
  Petrol = "Petrol",
  Electric = "Electric",
}
export enum ErrorEnum {
  RecentLoginError = "RecentLoginError",
  Unknown = "Unknown",
  ServerError = "server Error",
  Error400 = "400",
  Error408 = "408",
  Error409 = "409",
  Error416 = "416",
  Error417 = "417",
  Error419 = "419",
  Error428 = "428",
  Error429 = "429",
  IpError = "IpError",
}
export enum SuccessEnum {
  Success200 = "200",
  Success201 = "201",
  LogOut = "LogOut",
}
export enum InsurancePolicyTypeEnum {
  ComprehensiveInsurance = "Comprehensive Insurance",
  ThirdPartyPropertyDamage = "Third Party Property Damage",
}
export interface AddressType {
  address_components: [
    {
      long_name: string;
      short_name: string;
      types: string[];
    }
  ];
  formatted_address: string;
}

export function InsurancePolicyType(
  type: InsurancePolicyTypeEnum | null | undefined
) {
  if (type === InsurancePolicyTypeEnum.ComprehensiveInsurance) {
    return "Comprehensive Insurance";
  } else if (type === InsurancePolicyTypeEnum.ThirdPartyPropertyDamage) {
    return "Third Party Property Damage";
  }
  return "unknown";
}

export const SignUpState = types
  .model("SignUpState", {
    email: types.optional(types.string, ""),
    emailValid: types.optional(types.boolean, false),
    registrationEmail: types.optional(types.string, ""),
    registrationEmailValid: types.optional(types.boolean, false),
    confirmEmail: types.optional(types.string, ""),
    confirmEmailValid: types.optional(types.boolean, false),
    password: types.optional(types.string, ""),
    passwordValid: types.optional(types.boolean, false),
    confirmPassword: types.optional(types.string, ""),
    confirmPasswordValid: types.optional(types.boolean, false),
    phoneNumber: types.optional(types.string, ""),
    phoneNumberValid: types.optional(types.boolean, false),
    uid: types.maybeNull(types.string),
    token: types.optional(types.string, ""),
    authentication: types.maybeNull(
      types.enumeration([...Object.values(AuthenticationStepsEnum)])
    ),
    timer: types.optional(types.boolean, false),
    latestSignUpDate: types.maybeNull(types.number),
  })
  .actions((self) => ({
    setEmailAuthentication() {
      self.authentication = AuthenticationStepsEnum.Email;
    },
    setPasswordAuthentication() {
      self.authentication = AuthenticationStepsEnum.Password;
    },
    setPhoneNumberAuthentication() {
      self.authentication = AuthenticationStepsEnum.MobileNumber;
    },
    setEmail(value: string) {
      self.email = value;
    },
    setEmailValid(value: boolean) {
      self.emailValid = value;
    },
    setRegistrationEmail(value: string) {
      self.registrationEmail = value;
    },
    setRegistrationEmailValid(value: boolean) {
      self.registrationEmailValid = value;
    },
    setUid(value: string) {
      self.uid = value;
    },
  }))
  .actions((self) => ({
    setConfirmEmail(value: string) {
      self.confirmEmail = value;
    },
    setConfirmEmailConfirmValid(value: boolean) {
      self.confirmEmailValid = value;
    },
    setPassword(value: string) {
      self.password = value;
    },
    setPasswordValid(value: boolean) {
      self.passwordValid = value;
    },
    setConfirmPassword(value: string) {
      self.confirmPassword = value;
    },
    setConfirmPasswordValid(value: boolean) {
      self.confirmPasswordValid = value;
    },
    setPhoneNumber(value: string) {
      self.phoneNumber = value;
    },
    setPhonNumberValid(value: boolean) {
      self.phoneNumberValid = value;
    },
    setToken(value: string) {
      self.token = value;
    },
    setTimer(value: boolean) {
      self.timer = value;
    },

    setLatestSignUpDate(value: number | null) {
      self.latestSignUpDate = value;
    },
    sendSignInLinkToEmail: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        yield api.sendSignInLinkToEmail(self.registrationEmail);
        return true;
      } catch (err: any) {
        error(err);

        appState.setErrorMessage({
          message: err?.message || "unknown error!",
          error: ErrorEnum.Unknown,
        });
        // throw err;
      }
    }),
    isSignInWithEmailLink: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      try {
        const result: TSignUpResponse = yield api.isSignInWithEmailLink();
        self.setUid(result?.uid);
        self.setRegistrationEmail(result?.email);
        return true;
      } catch (err: any) {
        error(err);
        self.uid = null;
      }
    }),
    setPasswordInFirebase: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        yield api.setPasswordInFirebase(self.password);
        return true;
      } catch (err: any) {
        appState.setErrorMessage({
          message: err?.message || "unknown error!",
          error: ErrorEnum.RecentLoginError,
        });
        error(err);
        throw err;
      }
    }),

    reauthenticateWithCredential: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      try {
        yield api.reauthenticateWithCredential();
      } catch (err) {
        error(err);
      }
    }),
    onboardingNotifySetPassword: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);

      const ip = window.localStorage.getItem("ip");
      // if (ip === undefined || ip === null || ip === "") {
      //   alert("please turn off your antivirus because we can't access your ip");
      //   return false;
      // }

      const request: TOnboardingNotifySetPasswordRequest = {
        email_address: self.registrationEmail,
        ip,
      };
      try {
        const result: TOnboardingNotifySetPasswordResponse =
          yield api.onboardingNotifySetPassword(request);
        if (result.code === 200) {
          self.setPhoneNumberAuthentication();
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),
  }))
  .actions((self) => ({
    sendSMS: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const request: TOnboardingSendSMSRequest = {
          mobile_number:
            "+61" +
            appState.registration.signUp.phoneNumber.substring(
              1,
              appState.registration.signUp.phoneNumber.length
            ),
        };
        const result: TOnboardingSendSMSResponse = yield api.onboardingSendSMS(
          request
        );
        if (result.code === 200) {
          self.setTimer(true);
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) { }
    }),
    ConfirmSMS: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const request: TOnboardingConfirmSMSRequest = {
          token: appState.registration.signUp.token,
        };
        const result: TOnboardingConfirmSMSResponse =
          yield api.onboardingConfirmSMS(request);
        if (result.code === 200) {
          // appState.registration.setPersonalDetailsStep();
          appState.setSuccessMessage({
            message: result.message || "unknown error",
            successCode: SuccessEnum.LogOut,
          });
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 400 || result.code === 423) {
          throw result.message;
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        throw err;
      }
    }),
  }))

  .actions((self) => ({
    setUserPassword: async () => {
      const r1 = await self.setPasswordInFirebase();
      if (r1) {
        await self.onboardingNotifySetPassword();
      }
    },
  }));

export interface ISignUpState extends Instance<typeof SignUpState> { }
export interface ISignUpStateSnapshot extends SnapshotIn<typeof SignUpState> { }

export const LoginState = types
  .model("LoginState", {
    email: types.optional(types.string, ""),
    emailValid: types.optional(types.boolean, false),
    password: types.optional(types.string, ""),
    loggedin: types.optional(types.boolean, false),
    editing: types.optional(types.boolean, true),
    uid: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value;
    },
    setEmailValid(value: boolean) {
      self.emailValid = value;
    },
    setPassword(value: string) {
      self.password = value;
    },
    setEditing(value: boolean) {
      if (value) {
        self.editing = true;
        const appState = getParentOfType(self, AppState);
        appState.forgetPassword.setEditing(false);
      } else {
        self.editing = false;
      }
    },

    login: flow<boolean, []>(function* () {
      self.loggedin = false;
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TLoginResponse = yield api.login(
          self.email,
          self.password
        );
        self.loggedin = true;
        self.uid = result.uid;
        appState.handleLoaded();
        return true;
      } catch (err: any) {
        error(err);
        self.loggedin = false;
        self.uid = null;
        throw err;
      }
    }),
    logout: flow<boolean, []>(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        yield api.logout();
        self.email = "";
        self.password = "";
        self.loggedin = false;
        self.uid = null;
        appState.registration.setNoStep();
      } catch (err: any) {
        error(err);
      }
      return false;
    }),
    signout: flow<boolean, []>(function* () {
      const api = getEnv<EnvType>(self).api;
      try {
        yield api.logout();
        self.loggedin = false;
        self.uid = null;
      } catch (err: any) {
        error(err);
      }
      return false;
    }),
  }));

export interface ILoginState extends Instance<typeof LoginState> { }
export interface ILoginStateSnapshot extends SnapshotIn<typeof LoginState> { }

export const ForgetPassWordState = types
  .model("ForgetPassWordState", {
    email: types.optional(types.string, ""),
    emailValid: types.optional(types.boolean, false),
    editing: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value;
    },
    setEmailValid(value: boolean) {
      self.emailValid = value;
    },
    setEditing(value: boolean) {
      if (value) {
        self.editing = true;
        const appState = getParentOfType(self, AppState);
        appState.login.setEditing(false);
      } else {
        self.editing = false;
      }
    },
    fetchResetPassword: flow<boolean, []>(function* () {
      const api = getEnv<EnvType>(self).api;
      try {
        yield api.resetPassword(self.email);
      } catch (err: any) {
        error(err);
      }
      return false;
    }),
  }));
export interface IForgetPassWordState
  extends Instance<typeof ForgetPassWordState> { }
export interface IForgetPassWordStateSnapshot
  extends SnapshotIn<typeof ForgetPassWordState> { }

export interface IErrorMessage {
  message: string;
  error: ErrorEnum;
}
export interface ISuccessMessage {
  message: string;
  successCode: SuccessEnum;
}

export const PersonalDetailsState = types
  .model("PersonalDetailsState", {
    avatar: types.optional(types.string, ""),
    driversLicenseFile: types.optional(types.string, ""),
    isIdPhotoCardUploaded: types.optional(types.boolean, false),
    firstName: types.optional(types.string, ""),
    firstNameValid: types.optional(types.boolean, false),
    lastName: types.optional(types.string, ""),
    middleName: types.optional(types.string, ""),
    lastNameValid: types.optional(types.boolean, false),
    aptNumber: types.optional(types.string, ""),
    unitNumber: types.optional(types.string, ""),
    streetNumber: types.optional(types.string, ""),
    streetNumberValid: types.optional(types.boolean, false),
    streetName: types.optional(types.string, ""),
    streetNameValid: types.optional(types.boolean, false),
    streetType: types.optional(types.string, ""),
    streetTypeValid: types.optional(types.boolean, false),
    state: types.optional(types.string, ""),
    country: types.optional(types.string, "AU"),
    city: types.optional(types.string, ""),
    cityValid: types.optional(types.boolean, false),
    postCode: types.optional(types.string, ""),
    posteCodeValid: types.optional(types.boolean, false),
    gender: types.optional(types.string, ""),
    birthday: types.maybeNull(types.string),
    birthdayValid: types.optional(types.boolean, false),
    checked: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get myUid(): string | null {
      return getParentOfType(self, AppState).login.uid;
    },
  }))
  .actions((self) => ({
    setFirstName(value: string) {
      self.firstName = value;
    },
    setMiddleName(value: string) {
      self.middleName = value;
    },
    setFirstNameValid(value: boolean) {
      self.firstNameValid = value;
    },
    setLastName(value: string) {
      self.lastName = value;
    },
    setLastNameValid(value: boolean) {
      self.lastNameValid = value;
    },
    setAptNumber(value: string) {
      self.aptNumber = value;
    },
    setUnitNumber(value: string) {
      self.unitNumber = value;
    },
    setDriversLicenseFile(value: string) {
      self.driversLicenseFile = value;
    },
    setIsIdPhotoCardUploaded(value: boolean) {
      self.isIdPhotoCardUploaded = value;
    },
    setStreetNumber(value: string) {
      self.streetNumber = value;
    },
    setStreetNumberValid(value: boolean) {
      self.streetNumberValid = value;
    },
    setStreetName(value: string) {
      self.streetName = value;
    },
    setStreetNameValid(value: boolean) {
      self.streetNameValid = value;
    },
    setStreetType(value: string) {
      self.streetType = value;
    },
    setStreetTypeValid(value: boolean) {
      self.streetTypeValid = value;
    },
    setState(value: string) {
      self.state = value;
    },
    setCity(value: string) {
      self.city = value;
    },
    setCityValid(value: boolean) {
      self.cityValid = value;
    },
    setPosteCode(value: string) {
      self.postCode = value;
    },
    setPostCodeValid(value: boolean) {
      self.posteCodeValid = value;
    },
    setGender(value: string) {
      self.gender = value;
    },
    setBirthday(value: string) {
      self.birthday = value;
    },
    setBirthdayValid(value: boolean) {
      self.birthdayValid = value;
    },
    setChecked(value: boolean) {
      self.checked = value;
    },
  }))
  .actions((self) => ({
    updateAvatar: flow(function* (image: Blob) {
      const api = getEnv<EnvType>(self).api;
      if (!self.myUid) return false;
      try {
        const result = yield api.uploadProfileImage(self.myUid, image);
        self.avatar = result;
      } catch (err: any) {
        console.log(err);
      }
    }),
    uploadDriversLicenseFile: flow(function* (file: Blob) {
      const api = getEnv<EnvType>(self).api;
      try {
        if (!self.myUid) return;
        const result = yield api.uploadDriversLicenseFile(self.myUid, file);
        self.driversLicenseFile = result;
      } catch (err: any) {
        error(err);
      }
    }),

    setPersonalDetails: flow(function* () {
      const avatar_url = self.avatar;
      const id_photo_card_url = self.driversLicenseFile;
      const is_id_photo_card_uploaded = self.isIdPhotoCardUploaded;
      const birth_date = self.birthday;
      const gender = self.gender;
      const given_name = self.firstName.trim();
      const last_name = self.lastName.trim();
      const apt_no = self.aptNumber;
      const unit_no = self.unitNumber;
      const street_no = self.streetNumber;
      const street_name = self.streetName;
      const street_type = self.streetType;
      const state = self.state;
      const country = self.country;
      const post_code = self.postCode;
      const region_area = self.city;
      const middle_name = self.middleName.trim();

      const request: TOnboardingPersonalDetailsRequest = {
        avatar_url,
        id_photo_card_url,
        is_id_photo_card_uploaded,
        birth_date,
        gender,
        given_name,
        last_name,
        middle_name,
        residential_address_segmented: {
          apt_no,
          unit_no,
          street_no,
          street_name,
          street_type,
          state,
          country,
          post_code,
          region_area,
        },
      };
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingPersonalDetailsResponse =
          yield api.onboardingPersonalDetails(request);
        error(result);

        switch (result.code) {
          case 200:
            appState.registration.setLegalDetailsStep();
            self.setChecked(true);
            self.setIsIdPhotoCardUploaded(false);
            break;
          case 401:
            appState.login.logout();
            break;
          case 500:
            appState.setErrorMessage({
              message: result.message || "unknown error",
              error: ErrorEnum.ServerError,
            });
            break;
          case 400:
            appState.setErrorMessage({
              message: result.message || "unknown error",
              error: ErrorEnum.ServerError,
            });
            break;
          default:
            break;
        }
      } catch (err: any) {
        appState.login.logout();
        appState.setErrorMessage({
          message:
            "We have some technical issues. Please try again. If the problems exists, please call support.",
          error: ErrorEnum.ServerError,
        });
        error(err);
      }
    }),
  }));

export interface IPersonalDetailsState
  extends Instance<typeof PersonalDetailsState> { }
export interface IPersonalDetailsStateSnapshot
  extends SnapshotIn<typeof PersonalDetailsState> { }

export const LegalDetailsState = types
  .model("LegalDetailsState", {
    legalName: types.optional(types.string, ""),
    legalNameValid: types.optional(types.boolean, false),
    abnNumber: types.optional(types.string, ""),
    abnNumberValid: types.optional(types.boolean, false),
    aptNumber: types.optional(types.string, ""),
    abnAndLegalNameError: types.optional(types.string, ""),
    unitNumber: types.optional(types.string, ""),
    streetNumber: types.optional(types.string, ""),
    StreetNumberValid: types.optional(types.boolean, false),
    streetName: types.optional(types.string, ""),
    streetNameValid: types.optional(types.boolean, false),
    streetType: types.optional(types.string, ""),
    StreetTypeValid: types.optional(types.boolean, false),
    state: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    cityValid: types.optional(types.boolean, false),
    country: types.optional(types.string, "AU"),
    postCode: types.optional(types.string, ""),
    postCodeValid: types.optional(types.boolean, false),
    phone: types.optional(types.string, ""),
    phoneValid: types.optional(types.boolean, false),
    legalType: types.optional(types.string, "Individual"),
    publicLiabilityInsuranceType: types.optional(
      types.string,
      "Public Liability Insurance Certificate"
    ),
    publicLiabilityInsuranceTypeValid: types.optional(types.boolean, false),
    publicLiabilityInsuranceCompany: types.optional(types.string, ""),
    publicLiabilityInsuranceComponyValid: types.optional(types.boolean, false),
    publicLiabilityInsuranceNumber: types.optional(types.string, ""),
    publicLiabilityInsuranceNumberValid: types.optional(types.boolean, false),
    publicLiabilityValidityDate: types.maybeNull(types.string),
    publicLiabilityInsuranceFile: types.optional(types.string, ""),
    publicLiabilityInsuranceFileValid: types.optional(types.boolean, false),
    checked: types.optional(types.boolean, false),
    firstChecked: types.optional(types.boolean, false),
    secondChecked: types.optional(types.boolean, false),
    thirdChecked: types.optional(types.boolean, false),
    riderRegisteredForGst: types.optional(types.boolean, false),
    publicLiabilityInsuranceNotProvided: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get myUid(): string | null {
      return getParentOfType(self, AppState).login.uid;
    },
  }))
  .actions((self) => ({
    setLegalName(value: string) {
      self.legalName = value;
    },
    setLegalNameValid(value: boolean) {
      self.legalNameValid = value;
    },
    setAbnNumber(value: string) {
      self.abnNumber = value;
    },
    setAbnNumberValid(value: boolean) {
      self.abnNumberValid = value;
    },
    setAptNumber(value: string) {
      self.aptNumber = value;
    },
    setAbnAndLegalNameError(value: string) {
      self.abnAndLegalNameError = value;
    },
    setUnitNumber(value: string) {
      self.unitNumber = value;
    },
    setStreetNumber(value: string) {
      self.streetNumber = value;
    },
    setStreetNumberValid(value: boolean) {
      self.StreetNumberValid = value;
    },
    setStreetName(value: string) {
      self.streetName = value;
    },
    setStreetNameValid(value: boolean) {
      self.streetNameValid = value;
    },
    setStreetType(value: string) {
      self.streetType = value;
    },
    setStreetTypeValid(value: boolean) {
      self.StreetTypeValid = value;
    },
    setState(value: string) {
      self.state = value;
    },
    setCity(value: string) {
      self.city = value;
    },
    setCityValid(value: boolean) {
      self.cityValid = value;
    },
    setPosteCode(value: string) {
      self.postCode = value;
    },
    setPostCodeValid(value: boolean) {
      self.postCodeValid = value;
    },
    setPhone(value: string) {
      if (value.length < 2) {
        if (!isNaN(+value) && value !== "0") {
          self.phone = `+61${value}`;
        } else {
          self.phone = "+61";
        }
      } else {
        const newVal = value.slice(1);
        if (!isNaN(+newVal) && newVal.length < 12) {
          self.phone = `+${newVal}`;
        }
      }
    },
    setPhoneValid(value: boolean) {
      self.phoneValid = value;
    },
    setPublicLiabilityInsuranceType(value: string) {
      self.publicLiabilityInsuranceType = value;
    },
    setPublicLiabilityInsuranceTypeValid(value: boolean) {
      self.publicLiabilityInsuranceTypeValid = value;
    },
    setPublicLiabilityInsurerCompany(value: string) {
      self.publicLiabilityInsuranceCompany = value;
    },
    setPublicLiabilityInsurerCompanyValid(value: boolean) {
      self.publicLiabilityInsuranceComponyValid = value;
    },
    setPublicLiabilityInsuranceNumber(value: string) {
      self.publicLiabilityInsuranceNumber = value;
    },
    setPublicLiabilityInsuranceNumberValid(value: boolean) {
      self.publicLiabilityInsuranceNumberValid = value;
    },
    setPublicLiabilityValidityDate(value: string) {
      self.publicLiabilityValidityDate = value;
    },
    setPublicLiabilityInsuranceFileValid(value: boolean) {
      self.publicLiabilityInsuranceFileValid = value;
    },
    setChecked(value: boolean) {
      self.checked = value;
    },
    setFirstChecked(value: boolean) {
      self.firstChecked = value;
    },
    setSecondChecked(value: boolean) {
      self.secondChecked = value;
    },
    setThirdChecked(value: boolean) {
      self.thirdChecked = value;
    },
    setRiderRegisteredForGst(value: boolean) {
      self.riderRegisteredForGst = value;
    },
    setPublicLiabilityInsuranceNotProvided(value: boolean) {
      self.publicLiabilityInsuranceNotProvided = value;
    },
  }))
  .actions((self) => ({
    setLegalDetails: flow(function* () {
      const legal_name = self.legalName;
      const abn = self.abnNumber;
      const apt_no = self.aptNumber;
      const unit_no = self.unitNumber;
      const street_no = self.streetNumber;
      const street_name = self.streetName;
      const street_type = self.streetType;
      const state = self.state;
      const country = self.country;
      const post_code = self.postCode;
      const region_area = self.city;
      const legal_type = "Individual";
      const phone_number = self.phone;
      const public_liability_insurance_type =
        self.publicLiabilityInsuranceNotProvided
          ? ""
          : "Public Liability Insurance Certificate";
      const public_liability_insurer_company =
        self.publicLiabilityInsuranceNotProvided
          ? ""
          : self.publicLiabilityInsuranceCompany;
      const public_liability_insurance_number =
        self.publicLiabilityInsuranceNotProvided
          ? ""
          : self.publicLiabilityInsuranceNumber;
      const public_liability_insurance_validity_date =
        self.publicLiabilityInsuranceNotProvided
          ? ""
          : self.publicLiabilityValidityDate;
      const public_liability_insurance_certificate_url =
        self.publicLiabilityInsuranceFile;
      const rider_registered_for_gst = self.riderRegisteredForGst;
      const public_liability_insurance_not_provided =
        self.publicLiabilityInsuranceNotProvided;
      const request: TOnboardingLegalDetailsRequest = {
        legal_name,
        abn,
        legal_address_segmented: {
          apt_no,
          unit_no,
          street_no,
          street_name,
          street_type,
          state,
          country,
          post_code,
          region_area,
        },
        phone_number,
        legal_type,
        public_liability_insurance_certificate_url,
        public_liability_insurance_type,
        public_liability_insurer_company,
        public_liability_insurance_number,
        public_liability_insurance_validity_date,
        rider_registered_for_gst,
        public_liability_insurance_not_provided,
      };
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingLegalDetailsResponse =
          yield api.onboardingLegalDetails(request);
        switch (result.code) {
          case 200:
            appState.registration.setModesOfTransportStep();
            self.setChecked(true);
            self.setFirstChecked(true);
            self.setSecondChecked(true);
            self.setThirdChecked(true);
            break;
          case 401:
            appState.login.logout();
            break;
          case 428:
            self.setAbnAndLegalNameError(
              "The provided information is not valid"
            );
            self.setLegalNameValid(false);
            self.setAbnNumberValid(false);
            appState.setErrorMessage({
              message: result.message || "unknown error",
              error: ErrorEnum.Error428,
            });
            break;
          case 429:
            self.setAbnAndLegalNameError("ABN is used by another user");
            self.setLegalNameValid(false);
            self.setAbnNumberValid(false);
            appState.setErrorMessage({
              message: result.message || "unknown error",
              error: ErrorEnum.Error429,
            });
            break;
          case 500:
            appState.setErrorMessage({
              message: result.message || "unknown error",
              error: ErrorEnum.ServerError,
            });
            break;
          case 499:
            appState.setErrorMessage({
              message: "AppCheck failed!",
              error: ErrorEnum.ServerError,
            });

            break;
          default:
            appState.setErrorMessage({
              message: "unknown error",
              error: ErrorEnum.ServerError,
            });
            break;
        }
      } catch (err: any) {
        error(err);
      }
    }),
    uploadPublicLiabilityInsuranceFile: flow(function* (file: Blob) {
      const api = getEnv<EnvType>(self).api;
      try {
        if (!self.myUid) return;
        const result = yield api.uploadPublicLiabilityInsuranceFile(
          self.myUid,
          file
        );
        self.publicLiabilityInsuranceFile = result;
      } catch (err: any) {
        error(err);
      }
    }),
  }));

export interface ILegalDetailsState
  extends Instance<typeof LegalDetailsState> { }
export interface ILegalDetailsStateSnapshot
  extends SnapshotIn<typeof LegalDetailsState> { }

export const ModesOfTransportState = types
  .model("ModesOfTransportState", {
    cycle: types.maybeNull(types.enumeration([...Object.values(CycleEnum)])),
    motorbike: types.maybeNull(
      types.enumeration([...Object.values(MotorbikeEnum)])
    ),
    own: types.maybeNull(types.enumeration([...Object.values(OwnEnum)])),
    motorbikeType: types.maybeNull(
      types.enumeration([...Object.values(MotorbikeTypeEnum)])
    ),
    engineCapacity: types.optional(types.string, ""),
    engineCapacityValid: types.optional(types.boolean, false),
    registrationNumber: types.optional(types.string, ""),
    RegistrationNumberValid: types.optional(types.boolean, false),
    make: types.optional(types.string, ""),
    makeValid: types.optional(types.boolean, false),
    model: types.optional(types.string, ""),
    modelValid: types.optional(types.boolean, false),
    state: types.optional(types.string, ""),
    regoValidityDate: types.maybeNull(types.string),
    regoCertificateUrl: types.optional(types.string, ""),
    isRegoCertificateUrlHasChanged: types.optional(types.boolean, false),
    isInsuranceUrlHasChanged: types.optional(types.boolean, false),
    regoCertificateUrlValid: types.optional(types.boolean, false),
    insurancePolicyType: types.maybeNull(
      types.enumeration([...Object.values(InsurancePolicyTypeEnum)])
    ),
    insurerCompany: types.optional(types.string, ""),
    insuranceComponyValid: types.optional(types.boolean, false),
    policyNumber: types.optional(types.string, ""),
    policyNumberValid: types.optional(types.boolean, false),
    validityDate: types.maybeNull(types.string),
    insuranceFile: types.optional(types.string, ""),
    insuranceFileValid: types.optional(types.boolean, false),
    motorbikeId: types.maybeNull(types.string),
    ipcPolicyCertificateUrl: types.optional(types.string, ""),
    editableButton: types.optional(types.boolean, false),
    activated: types.optional(types.boolean, false),
    open: types.optional(types.boolean, false),
    close: types.optional(types.boolean, false),
    isIpcPolicyCertificateUploaded: types.optional(types.boolean, false),
    isRegoCertificateUploaded: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get myUid(): string | null {
      return getParentOfType(self, AppState).login.uid;
    },
  }))
  .actions((self) => ({
    setCycle(value: CycleEnum) {
      self.cycle = value;
    },
    setMotorbike(value: MotorbikeEnum) {
      self.motorbike = value;
    },
    setOwn(value: OwnEnum) {
      self.own = value;
    },
    setMotorbikeType(value: MotorbikeTypeEnum) {
      self.motorbikeType = value;
    },
    setEngineCapacity(value: string) {
      self.engineCapacity = value;
    },
    setEngineCapacityValid(value: boolean) {
      self.engineCapacityValid = value;
    },
    setIsIpcPolicyCertificateUploaded(value: boolean) {
      self.isIpcPolicyCertificateUploaded = value;
    },
    setIsRegoCertificateUploaded(value: boolean) {
      self.isRegoCertificateUploaded = value;
    },
    setRegistrationNumber(value: string) {
      self.registrationNumber = value;
    },
    setRegistrationNumberValid(value: boolean) {
      self.RegistrationNumberValid = value;
    },
    setMake(value: string) {
      self.make = value;
    },
    setMakeValid(value: boolean) {
      self.makeValid = value;
    },
    setModel(value: string) {
      self.model = value;
    },
    setModelValid(value: boolean) {
      self.modelValid = value;
    },
    setState(value: string) {
      self.state = value;
    },
    setInsurancePolicyType(value: InsurancePolicyTypeEnum) {
      self.insurancePolicyType = value;
    },
    setInsurerCompany(value: string) {
      self.insurerCompany = value;
    },
    setInsurerCompanyValid(value: boolean) {
      self.insuranceComponyValid = value;
    },
    setPolicyNumber(value: string) {
      self.policyNumber = value;
    },
    setPolicyNumberValid(value: boolean) {
      self.policyNumberValid = value;
    },
    setValidityDate(value: string) {
      self.validityDate = value;
    },
    setRegoValidityDate(value: string) {
      self.regoValidityDate = value;
    },
    setInsuranceFileValid(value: boolean) {
      self.insuranceFileValid = value;
    },
    setRegoCertificateUrlValid(value: boolean) {
      self.regoCertificateUrlValid = value;
    },
    setIsRegoCertificateUrlHasChanged(value: boolean) {
      self.isRegoCertificateUrlHasChanged = value;
    },
    setIsInsuranceUrlHasChanged(value: boolean) {
      self.isInsuranceUrlHasChanged = value;
    },
    setMotorbikeId(value: string) {
      self.motorbikeId = value;
    },
    setIpcPolicyCertificateUrl(value: string) {
      self.ipcPolicyCertificateUrl = value;
    },
    setRegoCertificateUrl(value: string) {
      self.regoCertificateUrl = value;
    },
    setEditableButton(value: boolean) {
      self.editableButton = value;
    },
    setActivated(value: boolean) {
      self.activated = value;
    },
    setOpen(value: boolean) {
      self.open = value;
    },
  }))
  .actions((self) => ({
    uploadInsuranceFile: flow(function* (file: Blob) {
      const api = getEnv<EnvType>(self).api;
      try {
        if (!self.myUid) return;
        const result = yield api.uploadInsuranceFile(self.myUid, file);
        self.insuranceFile = result;
        self.ipcPolicyCertificateUrl = result;
        self.setIsIpcPolicyCertificateUploaded(true);
      } catch (err: any) {
        error(err);
      }
    }),
    uploadRegoCertificateFile: flow(function* (file: Blob) {
      const api = getEnv<EnvType>(self).api;
      try {
        if (!self.myUid) return;
        const result = yield api.uploadRegoCertificateFile(self.myUid, file);
        self.regoCertificateUrl = result;
        self.setIsRegoCertificateUploaded(true);
      } catch (err: any) {
        error(err);
      }
    }),
    addMotorbike: flow(function* () {
      const engine_size = self.engineCapacity;
      const ipc_insurer_company = self.insurerCompany;
      const ipc_policy_certificate_url = self.insuranceFile;
      const ipc_policy_number = self.policyNumber;
      const ipc_policy_type = self.insurancePolicyType;
      const ipc_validity_date = self.validityDate;
      const make = self.make;
      const model = self.model;
      const motorbike_type = self.motorbikeType;
      const registration_number = self.registrationNumber;
      const registration_state = self.state;
      const rego_certificate_url = self.regoCertificateUrl;
      const rego_validity_date = self.regoValidityDate;

      const request: TOnboardingAddMotorbikeRequest = {
        engine_size,
        ipc_insurer_company,
        ipc_policy_certificate_url,
        ipc_policy_number,
        ipc_policy_type,
        ipc_validity_date,
        make,
        model,
        motorbike_type,
        registration_number,
        registration_state,
        rego_certificate_url,
        rego_validity_date,
      };

      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingAddMotorbikeResponse =
          yield api.onboardingAddMotorbike(request);
        if (result.code === 200) {
          self.setMotorbikeId(result.motorbike_id);
          self.setIpcPolicyCertificateUrl(result.ipc_policy_certificate_url);
          self.setRegoCertificateUrl(result.rego_certificate_url);
          appState.registration.modesOfTransport.setMotorbike(
            MotorbikeEnum.HasMotorbike
          );
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 409) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error409,
          });
        } else if (result.code === 419) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error419,
          });
        } else if (result.code === 417) {
          appState.registration.setModesOfTransportStep();
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error417,
          });
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) { }
    }),
    editMotorbike: flow(function* () {
      const engine_size = self.engineCapacity;
      const ipc_insurer_company = self.insurerCompany;
      const ipc_policy_number = self.policyNumber;
      const ipc_policy_type = self.insurancePolicyType;
      const ipc_validity_date = self.validityDate;
      const make = self.make;
      const model = self.model;
      const motorbike_id = self.motorbikeId;
      const motorbike_type = self.motorbikeType;
      const registration_number = self.registrationNumber;
      const registration_state = self.state;
      const rego_certificate_url = self.regoCertificateUrl;
      const rego_validity_date = self.regoValidityDate;
      const is_ipc_policy_certificate_uploaded =
        self.isIpcPolicyCertificateUploaded;
      const is_rego_certificate_uploaded = self.isRegoCertificateUploaded;
      const ipc_policy_certificate_url = self.ipcPolicyCertificateUrl;

      const request: TOnboardingEditMotorbikeRequest = {
        engine_size,
        ipc_insurer_company,
        ipc_policy_certificate_url,
        ipc_policy_number,
        ipc_policy_type,
        ipc_validity_date,
        make,
        model,
        motorbike_id,
        motorbike_type,
        registration_number,
        registration_state,
        rego_certificate_url,
        rego_validity_date,
        is_ipc_policy_certificate_uploaded,
        is_rego_certificate_uploaded,
      };
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingEditMotorbikeResponse =
          yield api.onboardingEditMotorbike(request);

        if (result.code === 200) {
          appState.registration.modesOfTransport.setMotorbike(
            MotorbikeEnum.HasMotorbike
          );
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 409) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error409,
          });
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) { }
    }),
    removeMotorbike: flow(function* (motorbike_id: string) {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingRemoveMotorbikeResponse =
          yield api.onboardingRemoveMotorbike({
            motorbike_id,
          });
        if (result.code === 200) {
          appState.registration.modesOfTransport.setMotorbike(
            MotorbikeEnum.HasNotMotorbike
          );
          self.motorbikeId = null;
          self.motorbikeType = null;
          self.policyNumber = "";
          self.registrationNumber = "";
          self.policyNumber = "";
          self.own = null;
          self.model = "";
          self.make = "";
          self.insuranceFile = "";
          self.engineCapacity = "";
          self.state = "";
          self.regoValidityDate = null;
          self.insurerCompany = "";
          self.validityDate = null;
          self.insurancePolicyType = null;
          self.setActivated(false);
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 409) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error409,
          });
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) { }
    }),
    setModesOfTransport: flow(function* () {
      const cycling: boolean = self.cycle === CycleEnum.HasCycle;
      const request: TOnboardingModesOfTransportRequest = {
        cycling,
      };
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingModesOfTransportResponse =
          yield api.onboardingModesOfTransport(request);
        if (result.code === 200) {
          appState.registration.setStripeIdStep();
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),
  }));
export interface IModesOfTransportState
  extends Instance<typeof ModesOfTransportState> { }
export interface IModesOfTransportStateSnapshot
  extends SnapshotIn<typeof ModesOfTransportState> { }

export const StripeState = types
  .model("StripeState", {
    nextBtn: types.optional(types.boolean, false),
    backBtn: types.optional(types.boolean, false),
    checked: types.optional(types.boolean, false),
    stripeUrl: types.optional(types.string, ""),
    payoutsEnable: types.maybeNull(types.boolean),
    detailsSubmitted: types.maybeNull(types.boolean),
    stripeResponse: types.maybeNull(
      types.enumeration([...Object.values(StripeResponseEnum)])
    ),
  })
  .actions((self) => ({
    setNextBtn(value: boolean) {
      self.nextBtn = value;
    },
    setBackBtn(value: boolean) {
      self.backBtn = value;
    },
    setChecked(value: boolean) {
      self.checked = value;
    },
    setPayoutsEnable(value: boolean | null) {
      self.payoutsEnable = value;
    },
    setStripeUrl(value: string) {
      self.stripeUrl = value;
    },
    setDetailsSubmitted(value: boolean | null) {
      self.detailsSubmitted = value;
    },
    setSuccessful() {
      self.stripeResponse = StripeResponseEnum.Successful;
      self.nextBtn = true;
      self.checked = true;
      self.backBtn = true;
    },
    setUnSuccessful() {
      self.stripeResponse = StripeResponseEnum.UnSuccessful;
      self.nextBtn = true;
      self.checked = true;
      self.backBtn = true;
    },
    setStripeButton() {
      self.stripeResponse = StripeResponseEnum.StripeButton;
      self.nextBtn = false;
      self.checked = true;
      self.backBtn = true;
    },
    setMainStripeButton() {
      self.stripeResponse = StripeResponseEnum.MainStripeButton;
      self.nextBtn = false;
      self.backBtn = false;
    },
  }))
  .actions((self) => ({
    onboardingStripe: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingStripeResponse = yield api.onboardingStripe();
        if (result.code === 200) {
          appState.registration.setIdVerificationStep();
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),
  }))
  .actions((self) => ({
    onboardingGetStripeExpressLink: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingGetStripeExpressLinkResponse =
          yield api.onboardingGetStripeExpressLink();
        if (result.code === 200) {
          self.stripeUrl = result.account_url;
          self.setChecked(true);
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),
    showStripeResponse: () => {
      if (self.detailsSubmitted === null) {
        self.setMainStripeButton();
      } else if (self.detailsSubmitted === true) {
        if (self.payoutsEnable === true) {
          self.setSuccessful();
        } else {
          self.setUnSuccessful();
        }
      } else if (self.detailsSubmitted === false) {
        if (self.payoutsEnable === true) {
          self.setSuccessful();
        } else {
          self.setStripeButton();
        }
      }
    },
  }));
export interface IStripeState extends Instance<typeof StripeState> { }
export interface StripeStateSnapshot extends SnapshotIn<typeof StripeState> { }

export const IdVerificationState = types
  .model("IdVerificationState", {
    showNccBtn: types.optional(types.boolean, false),
    nextBtn: types.optional(types.boolean, false),
    backBtn: types.optional(types.boolean, false),
    checked: types.optional(types.boolean, false),
    url: types.optional(types.string, ""),
    status: types.maybeNull(types.string),
    verifyStatus: types.maybeNull(types.string),
    result: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setChecked(value: boolean) {
      self.checked = value;
    },
    setShowNccBtn(value: boolean) {
      self.showNccBtn = value;
    },
    setNextBtn(value: boolean) {
      self.nextBtn = value;
    },
    setBackBtn(value: boolean) {
      self.backBtn = value;
    },
    setUrl(value: string) {
      self.url = value;
    },
    setStatus(value: string | null) {
      self.status = value;
    },
    setResult(value: string | null) {
      self.result = value;
    },
    setVerifyStatus(value: string | null) {
      self.verifyStatus = value;
    },
    setMessage(value: string | null) {
      self.message = value;
    },
  }))
  .actions((self) => ({
    onboardingGetNccLink: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingGetNccLinkResponse =
          yield api.onboardingGetNccLink();
        if (result.code === 200) {
          self.url = result.continue_url;
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 400) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.Error400,
          });
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),

    onboardingNccNextButton: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);
      try {
        const result: TOnboardingNccNextButtonResponse =
          yield api.onboardingNccNextButton();
        if (result.code === 200) {
          appState.registration.setDigitalSignatureStep();
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),

    responseOfNCC: () => {
      if (self.verifyStatus === "") {
        if (self.status === "RECEIVED") {
          if (self.result === "NDCO") {
            self.checked = true;
            self.showNccBtn = false;
            self.nextBtn = true;
            self.message =
              "Your checks was successfully passed. Click Next to proceed.";
          } else if (self.result === "DCO") {
            self.checked = true;
            self.showNccBtn = false;
            self.nextBtn = false;
            self.message =
              "Unfortunately, the outcomes of your checks indicates that you application did not pass minimum requirements for being able to work as a Bearer. If you believe that the decision has not been made correctly, or should you have any inquiries, please contact us.";
          } else if (self.result === "PURGED") {
            self.checked = true;
            self.showNccBtn = false;
            self.nextBtn = true;
            self.message =
              "Your results has been expired. Check results are only valid for 3 months. For a new check, please click bellow button, follow the prompts and complete the forms to have the checks done.";
          }
        } else if (self.status === "UTP") {
          self.checked = true;
          self.showNccBtn = false;
          self.nextBtn = false;
          self.message = "Unavailable";
        } else if (self.status === "REFUNDED") {
          self.checked = true;
          self.showNccBtn = false;
          self.nextBtn = false;
          self.message =
            "Your checks has been cancelled by NCC and the paid amount has been refunded. Unfortunately, we are unable to process your application at this stage.";
        } else if (self.status === "") {
          self.checked = false;
          self.showNccBtn = true;
          self.nextBtn = false;
          self.message =
            "To start the process, please click bellow button, follow the prompts and complete the forms to have the checks done.";
        }
      } else if (self.verifyStatus === "PRIOR") {
        self.checked = true;
        self.showNccBtn = true;
        self.nextBtn = false;
        self.message =
          "To start the process, please click bellow button, follow the prompts and complete the forms to have the checks done.";
      } else if (self.verifyStatus === "APPLICANT") {
        self.checked = true;
        self.showNccBtn = true;
        self.nextBtn = false;
        self.message =
          "NCC process was not completed successfully. Please click bellow button, follow the prompts and complete the forms to have the checks done.";
      } else if (self.verifyStatus === "QA") {
        self.checked = true;
        self.showNccBtn = false;
        self.nextBtn = true;
        self.message =
          "Your application is being assessed by NCC to be sent for checks. You can click Next to continue your application while NCC is processing your request.";
      } else if (self.verifyStatus === "DONE") {
        self.checked = true;
        self.showNccBtn = false;
        self.nextBtn = true;
        self.message =
          "You application has been sent for the checks. It could take up to 48 hours (in most cases) for the results to become available. You can click Next to continue your application while NCC is processing your request.";
      } else if (self.verifyStatus === null) {
        self.checked = false;
        self.showNccBtn = false;
        self.nextBtn = false;
        self.message =
          "To start the process, please click bellow button, follow the prompts and complete the forms to have the checks done.";
      }
    },
  }));

export interface IIdVerificationState
  extends Instance<typeof IdVerificationState> { }
export interface IIdVerificationStateSnapshot
  extends SnapshotIn<typeof IdVerificationState> { }

export const DigitalSignatureState = types
  .model("DigitalSignatureState", {
    name: types.optional(types.string, ""),
    referredBy: types.optional(types.string, ""),
    referredCode: types.optional(types.string, ""),
    nameValid: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value;
    },
    setNameValid(value: boolean) {
      self.nameValid = value;
    },
    setReferredBy(value: string) {
      self.referredBy = value;
    },
    setReferredCode(value: string) {
      self.referredCode = value;
    },
  }))
  .actions((self) => ({
    setDigitalSignature: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const appState = getParentOfType(self, AppState);

      const ip = window.localStorage.getItem("ip");
      // if (ip === undefined || ip === null || ip === "") {
      //   alert("please turn off your antivirus because we can't access your ip");
      //   return false;
      // }

      const request: TOnboardingDigitalSignatureRequest = {
        name_as_signed: self.name,
        ip,
        referred_by: self.referredCode,
      };
      try {
        const result: TOnboardingDigitalSignatureResponse =
          yield api.onboardingDigitalSignature(request);
        if (result.code === 200) {
          appState.login.logout();
          appState.setSuccessMessage({
            message: result.message || "unknown error",
            successCode: SuccessEnum.Success200,
          });
        } else if (result.code === 401) {
          appState.login.logout();
        } else if (result.code === 400) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.Error400,
          });
        } else if (result.code === 500) {
          appState.setErrorMessage({
            message: result.message || "unknown error",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
    }),
  }));
export interface IDigitalSignatureState
  extends Instance<typeof DigitalSignatureState> { }
export interface IDigitalSignatureStateSnapshot
  extends SnapshotIn<typeof DigitalSignatureState> { }

export const RegistrationState = types
  .model("RegistrationState", {
    step: types.maybeNull(types.enumeration([...Object.values(StepsEnum)])),
    signUp: types.optional(SignUpState, {}),
    personalDetails: types.optional(PersonalDetailsState, {}),
    legalDetails: types.optional(LegalDetailsState, {}),
    modesOfTransport: types.optional(ModesOfTransportState, {}),
    stripe: types.optional(StripeState, {}),
    idVerification: types.optional(IdVerificationState, {}),
    digitalSignature: types.optional(DigitalSignatureState, {}),
  })
  .actions((self) => ({
    setNoStep() {
      self.step = null;
    },
    setTermsAndConditionStep() {
      self.step = StepsEnum.TermsAndConditionsAndPrivacyPolicy;
    },
    setSignUpStep() {
      self.step = StepsEnum.ContactDetails;
    },
    setPersonalDetailsStep() {
      self.step = StepsEnum.PersonalDetails;
    },
    setLegalDetailsStep() {
      self.step = StepsEnum.LegalDetails;
    },
    setModesOfTransportStep() {
      self.step = StepsEnum.ModesOfTransport;
    },
    setStripeIdStep() {
      self.step = StepsEnum.StripeOnboarding;
    },
    setIdVerificationStep() {
      self.step = StepsEnum.IDVerification;
    },
    setDigitalSignatureStep() {
      self.step = StepsEnum.DigitalSignature;
    },
  }));
export interface IRegistrationState
  extends Instance<typeof RegistrationState> { }
export interface IRegistrationStateSnapshot
  extends SnapshotIn<typeof RegistrationState> { }

export const AppState = types
  .model("AppState", {
    login: types.optional(LoginState, {}),
    forgetPassword: types.optional(ForgetPassWordState, {}),
    loaded: types.optional(types.boolean, false),
    serverSync: types.optional(types.boolean, false),
    noCloudFunctions: types.maybeNull(types.frozen<NoCloudFunctionData>()),
    noOnboarding: types.maybeNull(types.frozen<NoOnboardingData>()),
    remoteConfigBaseUrl: types.maybeNull(types.frozen<RemoteConfigBaseUrl>()),
    serverChecked: types.optional(types.boolean, false),
    errorMessage: types.maybeNull(types.frozen<IErrorMessage>()),
    successMessage: types.maybeNull(types.frozen<ISuccessMessage>()),
    onboardingRemoteConfigParameters: types.maybeNull(
      types.frozen<OnboardingRemoteConfigParameters>()
    ),
    registration: types.optional(RegistrationState, {}),
    url: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setErrorMessage(value?: IErrorMessage) {
      self.errorMessage = value || null;
    },
    setSuccessMessage(value?: ISuccessMessage) {
      self.successMessage = value || null;
    },
  }))
  .actions((self) => ({
    onboardingLoginPermission: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      const result: TOnboardingLoginPermissionResponse =
        yield api.onboardingLoginPermission();
      try {
        if (result.code === 200) {
          self.registration = RegistrationState.create(
            extractRegistrationState(result)
          );
          self.registration.stripe.setPayoutsEnable(
            result.stripe?.payouts_enable!
          );
          self.registration.stripe.setDetailsSubmitted(
            result.stripe?.details_submitted!
          );
          self.registration.idVerification.setStatus(
            result.ncc?.latest_status!
          );
          self.registration.idVerification.setVerifyStatus(
            result.ncc?.latest_verify_status!
          );
          self.registration.idVerification.setResult(
            result.ncc?.latest_result!
          );
          self.registration.signUp.setLatestSignUpDate(
            result.latest_tc_pp_vers_sign_date?._seconds!
          );
        } else if (result.code === 201) {
          self.registration.setSignUpStep();
          self.registration.signUp.setPasswordAuthentication();
        } else if (result.code === 401) {
          self.login.logout();
        } else if (result.code === 408) {
          self.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.Error408,
          });
        } else if (result.code === 416) {
          self.url = result.redirect_url;
          self.login.logout();
          self.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.Error416,
          });
        } else if (result.code === 500) {
          self.setErrorMessage({
            message: result.message || "unknown error!",
            error: ErrorEnum.ServerError,
          });
        }
      } catch (err: any) {
        error(err);
      }
      return false;
    }),
  }))
  .actions((self) => ({
    getOnboardingRemoteConfigParameters: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      ///// To solve AppCheck - Sign In With Email Address Link - Auth / action link error
      api.callApiWithAppCheck();
      yield api.fetchRemoteConfigurations();
      self.onboardingRemoteConfigParameters =
        api.bearerOnboardingRemoteConfigParameters();
    }),
    checkServer: flow(function* () {
      const api = getEnv<EnvType>(self).api;
      api.callApiWithAppCheck();
      yield api.fetchRemoteConfigurations();
      self.noCloudFunctions = api.bearerCloudFunctionIsRunning();
      if (self.noCloudFunctions) return;
      self.noOnboarding = api.bearerOnboardingIsRunning();
      if (self.noOnboarding) return;
      self.remoteConfigBaseUrl = api.remoteConfigBaseUrlIsGetting();
      self.serverChecked = true;
    }),
    handleLoaded: flow(function* () {
      try {
        // const ip = window.localStorage.getItem("ip");
        // if (ip === undefined || ip === null || ip === "") {
        //   window.localStorage.removeItem("ip");
        //   self.login.logout();
        //   self.setErrorMessage({
        //     message:
        //       "For legal purposes, during the registration, we need to have access to your IP address. Please turn off anti-virus software and try again.",
        //     error: ErrorEnum.IpError,
        //   });
        // } else {
        ///// To solve AppCheck - Sign In With Email Address Link - Auth / action link error
        const api = getEnv<EnvType>(self).api;
        api.callApiWithAppCheck();
        yield self.registration.signUp.isSignInWithEmailLink();
        yield self.onboardingLoginPermission();
        // }
        self.serverSync = true;
      } catch (err) {
        error(err);
      }
    }),
  }));

export const AppStoreContext = React.createContext<IAppState | null>(null);
export const AppStateProvider: React.FC = ({ children }) => {
  const store = useLocalObservable(createStore);
  React.useEffect(() => {
    if (!store.loaded) {
      BearerApi.onLoad(() => {
        if (!store.serverSync) {
          store.handleLoaded();
        }
      });
    } else {
      store.handleLoaded();
    }
  }, [store]);
  return (
    <AppStoreContext.Provider value={store}>
      {children}
    </AppStoreContext.Provider>
  );
};
export const useAppState = () => {
  const store = React.useContext(AppStoreContext);
  if (!store) {
    throw new Error("useAppStore must be used within StoreProvider");
  }
  return store;
};
export type EnvType = {
  api: TBearerApi;
};
export function createStore() {
  const injection: EnvType = {
    api: BearerApi,
  };
  let store;
  try {
    const snapshot = loadStore();
    snapshot.serverChecked = false;
    snapshot.serverSync = false;
    snapshot.loaded = BearerApi.isLoaded();
    store = AppState.create(snapshot, injection);
  } catch (err) {
    log.warn("could not create the state,fallback to empty one.");
    log.warn(err);
    store = AppState.create(emptyStore(), injection);
  }
  onSnapshot(store, saveStore);
  return store;
}
export function createTestStore(snapshot: IAppStateSnapshot): IAppState {
  const injection: EnvType = {
    api: BearerApi,
  };
  const store = AppState.create(snapshot, injection);
  return store;
}
export const SNAPSHOT_KEY = "app-store";
function loadStore(): IAppStateSnapshot {
  const snapshot = localStorage.getItem(SNAPSHOT_KEY);
  try {
    return snapshot ? JSON.parse(snapshot) : emptyStore();
  } catch (err) {
    log.warn("could not create the state,fallback to empty one.");
    log.warn(err);
    return emptyStore();
  }
}
function saveStore(snapshot: IAppStateSnapshot) {
  // localStorage.setItem(SNAPSHOT_KEY, JSON.stringify(snapshot));
  localStorage.setItem(
    SNAPSHOT_KEY,
    JSON.stringify({
      login: snapshot.login,
    })
  );
}
function emptyStore(): IAppStateSnapshot {
  return {};
}
export interface IAppState extends Instance<typeof AppState> { }
export interface IAppStateSnapshot extends SnapshotIn<typeof AppState> { }

function extractRegistrationState(
  result: TOnboardingLoginPermissionResponse
): IRegistrationStateSnapshot | undefined {
  if (result.code === 200) {
    return {
      step: convertServerStepToEnum(
        result.sign_up_stage,
        result.sign_up_sub_stage
      ),
      signUp: {
        authentication: convertServerAuthenticateToEnum(
          result.sign_up_stage,
          result.sign_up_sub_stage
        ),
        email: result.email_address,
        confirmEmail: result.email_address,
      },
      personalDetails: {
        firstName: result.given_name,
        lastName: result.last_name,
        middleName: result.middle_name,
        avatar: result.avatar_url,
        driversLicenseFile: result.id_photo_card_url,
        isIdPhotoCardUploaded: result.is_id_photo_card_uploaded,
        birthday: result.birth_date,
        gender: result.gender,
        aptNumber: result.residential_address_segmented?.apt_no,
        unitNumber: result.residential_address_segmented?.unit_no,
        streetNumber: result.residential_address_segmented?.street_no,
        streetName: result.residential_address_segmented?.street_name,
        streetType: result.residential_address_segmented?.street_type,
        city: result.residential_address_segmented?.region_area,
        postCode: result.residential_address_segmented?.post_code,
        state: result.residential_address_segmented?.state,
        checked: result.given_name ? true : false,
      },
      legalDetails: {
        legalName: result.legal_details?.legal_name,
        abnNumber: result.legal_details?.abn,
        phone: result.legal_details?.phone_number,
        aptNumber: result.legal_details?.legal_address_segmented?.apt_no,
        unitNumber: result.legal_details?.legal_address_segmented?.unit_no,
        streetNumber: result.legal_details?.legal_address_segmented?.street_no,
        streetName: result.legal_details?.legal_address_segmented?.street_name,
        streetType: result.legal_details?.legal_address_segmented?.street_type,
        city: result.legal_details?.legal_address_segmented?.region_area,
        postCode: result.legal_details?.legal_address_segmented?.post_code,
        state: result.legal_details?.legal_address_segmented?.state,
        checked: result.legal_details?.abn ? true : false,
        firstChecked: result.legal_details?.abn ? true : false,
        secondChecked: result.legal_details?.abn ? true : false,
        thirdChecked: result.legal_details?.abn ? true : false,
        publicLiabilityInsuranceCompany:
          result.public_liability_insurance?.insurer_company,
        publicLiabilityInsuranceType:
          result.public_liability_insurance?.insurance_type,
        publicLiabilityInsuranceNumber:
          result.public_liability_insurance?.insurance_number,
        publicLiabilityValidityDate:
          result.public_liability_insurance_validity_date,
        riderRegisteredForGst: result.rider_registered_for_gst,
        publicLiabilityInsuranceNotProvided:
          result.public_liability_insurance_not_provided,
      },
      modesOfTransport: {
        own: OwnEnum.Yes,
        cycle: result.available_services?.cycling
          ? CycleEnum.HasCycle
          : CycleEnum.HasNotCycle,
        motorbike: result.motorbike
          ? MotorbikeEnum.HasMotorbike
          : MotorbikeEnum.HasNotMotorbike,
        motorbikeType: Object.values(MotorbikeTypeEnum).find(
          (i) => i === result.motorbike?.motorbike_type
        ),
        motorbikeId: result.motorbike?.motorbike_uid,
        regoCertificateUrl: result.motorbike?.rego_certificate_url,
        ipcPolicyCertificateUrl: result.motorbike?.ipc_policy_certificate_url,
        engineCapacity: result.motorbike?.engine_size,
        registrationNumber: result.motorbike?.registration_number,
        make: result.motorbike?.make,
        model: result.motorbike?.model,
        state: result.motorbike?.registration_state,
        insurerCompany: result.motorbike?.ipc_insurer_company,
        policyNumber: result.motorbike?.ipc_policy_number,
        validityDate: result.motorbike?.ipc_validity_date,
        insurancePolicyType: Object.values(InsurancePolicyTypeEnum).find(
          (i) => i === result.motorbike?.ipc_policy_type
        ),
        regoValidityDate: result.motorbike?.rego_validity_date,
      },
      stripe: {
        checked:
          result.stripe?.payouts_enable === true ||
          result.stripe?.payouts_enable === false,
        payoutsEnable: result.stripe?.payouts_enable,
        detailsSubmitted: result.stripe?.details_submitted,
      },
      idVerification: {
        // enabled:
        // disabled:
      },
    };
  }
}

function convertServerStepToEnum(
  sign_up_stage: number | undefined,
  sign_up_sub_stage: number | undefined
): StepsEnum | null | undefined {
  if (sign_up_stage === 1) {
    if (sign_up_sub_stage === 1 || sign_up_sub_stage === 2) {
      return StepsEnum.ContactDetails;
    } else if (sign_up_sub_stage === 3) {
      return StepsEnum.PersonalDetails;
    }
  } else if (sign_up_stage === 2) {
    return StepsEnum.LegalDetails;
  } else if (sign_up_stage === 3) {
    return StepsEnum.ModesOfTransport;
  } else if (sign_up_stage === 4) {
    return StepsEnum.StripeOnboarding;
  } else if (sign_up_stage === 5) {
    return StepsEnum.IDVerification;
  } else if (sign_up_stage === 6) {
    return StepsEnum.DigitalSignature;
  } else if (sign_up_stage === 7) {
    return undefined;
  }
  return undefined;
}
function convertServerAuthenticateToEnum(
  sign_up_stage: number | undefined,
  sign_up_sub_stage: number | undefined
): AuthenticationStepsEnum | null | undefined {
  if (sign_up_stage === 1) {
    if (sign_up_sub_stage === 1) {
      return AuthenticationStepsEnum.Password;
    } else if (sign_up_sub_stage === 2) {
      return AuthenticationStepsEnum.MobileNumber;
    }
  }
}
