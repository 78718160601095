import React, { useEffect } from "react";
import { CircularProgress, Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import { AppStateProvider, useAppState } from "../states/app-state";
import CheckServerRunningStates from "./CheckServerRunningStates";
import ErrorMessages from "./ErrorMessages";
import RegistrationSteps from "./RegistrationSteps";
import SuccessMessages from "./SuccessMessages";
import MainPage from "./MainPage";
import "react-toastify/dist/ReactToastify.css";
import GoogleTagManager from "../components/tags/googleTagManager";
import FacePixel from "../components/tags/facePixel";
import HotjarTracking from "../components/tags/hotjar";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";

const Scaffold: React.FC = observer(() => {
  const appState = useAppState();
  const environment = appState.remoteConfigBaseUrl?.environment_code
  const isActiveTabExist = window.localStorage.getItem("activeTab")?.length === 1
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isIpAddress, setIsAddress] = React.useState(false);

  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) {
        const response = await fetch("https://api.ipify.org/?format=json");
        if (!response.ok) {
          window.localStorage.removeItem("ip");
          setDialogOpen(true);
          setIsAddress(false);
        } else {
          const data = await response.json();
          setIsAddress(true);
          window.localStorage.setItem("ip", data.ip);
        }
      } else {
        const data = await response.json();
        setIsAddress(true);
        window.localStorage.setItem("ip", data.ip);
      }
    } catch (err) {
      window.localStorage.removeItem("ip");
      setDialogOpen(true);
      setIsAddress(false);
    }
  };


  useEffect(() => {
    getIp();
  }, []);



  const renderContent = (appState: any) => {
    switch (true) {
      case appState.registration.step && appState.login.loggedin:
        return <RegistrationSteps />;
      case appState.registration.step && !appState.login.loggedin:
        return <RegistrationSteps />;
      case !appState.registration.step && !appState.login.loggedin && window.location.pathname === '/new' && !isActiveTabExist:
        appState.registration.setTermsAndConditionStep();
        return <RegistrationSteps />;
      case !appState.registration.step && !appState.login.loggedin && isActiveTabExist:
        return <MainPage />;
      default:
        return <MainPage />;
    }
  };

  return (
    <>
      {environment === "PRODUCTION" &&
        <>
          <GoogleTagManager />
          <FacePixel />
          <HotjarTracking />
        </>
      }
      <Container maxWidth={false} style={{ padding: 0, margin: "0 auto" }}>
        {!appState.serverSync && (
          <CircularProgress sx={{ display: "block", margin: "100px auto" }} />
        )}
        {appState.serverSync && (
          <>
            {appState.serverChecked && (
              <>
                {isIpAddress && renderContent(appState)}
                < ErrorMessages />
                <SuccessMessages />
              </>
            )}
            {!appState.serverChecked && <CheckServerRunningStates />}
          </>
        )}
      </Container>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <DialogContentText>
            For legal purposes, during the registration, we need to have access
            to your IP address. Please turn off anti-virus software and try
            again.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
});
export default Scaffold;